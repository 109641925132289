export { SimpleTooltip } from './components/SimpleTooltip.tsx'
import { Trigger, Root, Arrow, Provider } from '@radix-ui/react-tooltip'

import { Content } from './components/Content.tsx'

export const TooltipPrimitives = {
  Trigger,
  Content,
  Root,
  Arrow,
  Provider,
}
