import { useRef, useState } from 'react'

import { TooltipPrimitives } from '@components/Tooltip'

import type { ReactNode } from 'react'

export function SimpleTooltip({
  children,
  content,
}: {
  readonly content: ReactNode | string
  readonly children: ReactNode
}) {
  const timerRef = useRef<ReturnType<typeof setTimeout>>()
  const [open, setOpen] = useState(false)
  const triggerRef = useRef(null)
  return (
    <TooltipPrimitives.Provider delayDuration={100}>
      <TooltipPrimitives.Root
        onOpenChange={(isOpen) => {
          setOpen(isOpen)
        }}
        open={open}
      >
        <TooltipPrimitives.Trigger
          asChild
          onClick={(event) => {
            event.preventDefault()
          }}
          onTouchEnd={() => {
            setOpen(true)
            if (timerRef.current) {
              clearTimeout(timerRef.current)
            }

            timerRef.current = setTimeout(() => {
              setOpen(false)
            }, 3000)
          }}
          ref={triggerRef}
        >
          {children}
        </TooltipPrimitives.Trigger>
        <TooltipPrimitives.Content
          onPointerDownOutside={(event) => {
            if (event.target === triggerRef.current) {
              event.preventDefault()
            }
          }}
        >
          {content}
          <TooltipPrimitives.Arrow className="tw-fill-white" />
        </TooltipPrimitives.Content>
      </TooltipPrimitives.Root>
    </TooltipPrimitives.Provider>
  )
}
